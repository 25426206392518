.rounded-overflow {
  border: 1px solid rgb(70, 70, 70);
  border-radius: 10px;
}
.border {
  border: 1px solid rgb(70, 70, 70);
  overflow: hidden;
}

.rounded {
  border: 1px solid rgb(70, 70, 70);
  border-radius: 10px;
  overflow: hidden;
}
.padded {
  padding: 20px;
  box-sizing: border-box;
}
.grow {
  flex-grow: 1;
  flex-basis: 0;
}
.wrap {
  flex-wrap: wrap;
}
p {
  color: white;
  padding: 0px;
  margin: 0px;
}
div {
  color: white;
}

/* h3 {
  font-size: 30px;
  font-weight: 400;
  margin: 10px 0px;
  /* text-align: center; */
/* flex: 0 0 100%; */
/* color: #fff; */

h2 {
  font-size: 36px;
  font-weight: 400;
  margin: 10px 0px;
  text-align: center;
  /* flex: 0 0 100%; */
  color: #fff;
}
.daily-background {
  background: #b35252;
}
.season-background {
  background: #52a1b3;
}
.weekly-background {
  background: #7ebd7e;
}
.community-background {
  background: #a9b352;
}
.light-background {
  background: #282828;
}
.button-container {
  background-color: #222;
  color: white;
  float: left;
  /* flex: 20px 2; */
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: stretch;
}

.horizontal-fill{
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.horizontal-fill>* {
  flex: 1;
}

.horizontal-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}


.wide-gap {
  gap: 40px;
  /* gap: 150px; */
  /* gap: 400px; */
}
@font-face {
  font-family: "ConthraxSB";
  src: local("Conthrax2"), url(font/conthrax-sb.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
.conthrax {
  font-family: ConthraxSB;
  font-weight: 100;
  /* font-size: 10; */
  /* font-size: 1.2vmin; */
  /* letter-spacing: 5px; */
  letter-spacing: 7px;
  line-height: 10px;
  height: 10px;
}
.conthrax-thin {
  font-family: ConthraxSB;
  font-weight: 100;
  /* font-size: 10; */
  /* font-size: 1.2vmin; */
  /* letter-spacing: 5px; */
  /* letter-spacing: 7px; */
  line-height: 10px;
  height: 10px;
}

.text-container {
  display: flex;
  align-items: center;
  background-color: #333;
  padding: 10px;
  /* margin: 10px 0px; */
  text-decoration: none;
  color: white;
  line-height: 0;
  /* font-size: 15px; */
  line-height: 1.5;
}

.button {
    background-color: #222;
  display: flex;
  align-items: center;
  /* background-color: #333; */
  padding: 10px;
  /* margin: 10px 0px; */
  text-decoration: none;
  color: white;
  line-height: 0;
  /* font-size: 15px; */
  line-height: 1.5;
  cursor: pointer;
  animation: FadeIn 0.2s;
  text-align: center;
}

.button h1 {
  font-size: 36px;
  font-weight: 400;
  margin: 10px 0px;
  text-align: center;
  flex: 0 0 100%;
  color: #fff;
  text-align: center;
}
h3 {
  margin: 0px;
}
.centering {
  display: flex;
  justify-content: center;
  align-content: center;
  ;
  /* flex-direction: column; */
  text-align: center;
  align-self: center;
}
.centered {
  text-align: center;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.fill {
  width: 100%;
  height: 100%;
}
.no-gap {
  gap: 0px;
}

.button:hover {
  background-color: #555;
  color: #000;
}

.disabled-button {
  display: flex;
  align-items: center;
  background-color: #262626;
  padding: 10px;
  color: rgb(70, 70, 70);
  /* margin: 10px 0px; */
  text-decoration: none;
  color: white;
  line-height: 0;
  /* font-size: 15px; */
  line-height: 1.5;
  cursor: not-allowed;
  animation: FadeIn 0.2s;
}
/* .list .button {
  flex-grow: 1;
} */

.container h1 {
  text-align: center;
  color: white;
}
.list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: stretch;
}
.list > p{
  text-align: center;
  margin: 10px 0px;
}
.list h2 {
  font-size: 36px;
  font-weight: 400;
  margin: 10px 0px;
  text-align: center;
  /* flex: 0 0 100%; */
  color: #fff;
}
.hoverable:hover {
  background-color: #555;
  color: #000;
}

.button:hover {
  background-color: #555;
  color: #000;
}
.container-title {
  font-size: 36px;
  font-weight: 400;
  margin: 10px 0px;
  text-align: center;
  flex: 0 0 100%;
  color: #fff;
  flex-grow: 0;
}

.border{
    border: 1px solid rgb(70, 70, 70);
}
.border-thick{
    border: 4px solid rgb(70, 70, 70);
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.no-gap {
  gap: 0px;
}
.double-font {
  font-size: 2em;
}

.grid-container {
  display: grid;
  grid-template-areas:
    'topbar topbar'
    'left right'
    'bottombar bottombar';
  gap: 4px;
  background-color: rgb(70, 70, 70);
  
  padding: 4px;
}